import React from "react";
import PropTypes from "prop-types";

class PersonBrief extends React.Component {
  render() {
    return (
      <div className="person-brief-container">
        <div className="person-brief-box">
          <label>{this.props.name}</label>
          <label>{this.props.operation}</label>
        </div>
      </div>
    );
  }
}

PersonBrief.propTypes = {
  name: PropTypes.string,
  operation: PropTypes.string
};

export default PersonBrief;
