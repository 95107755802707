import React from "react";
import PropTypes from "prop-types";

class PersonImage extends React.Component {
  render() {
    return <img src={this.props.image} className="person-card-image" />;
  }
}

PersonImage.propTypes = {
  image: PropTypes.string
};

export default PersonImage;
