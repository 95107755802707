import React from "react";
import Search from "../../images/search.svg";
import "../../css/bootstrap.min.css";
import "../../css/index.css";

class SearchInput extends React.Component {
  render() {
    return (
      <div className="input-search-container">
        <input
          type="text"
          placeholder="قسمتی از نام شهید را تایپ نمایید"
          className="input-search"
        />
        <div className="input-search-icon-container">
          <img src={Search} />
        </div>
      </div>
    );
  }
}

export default SearchInput;
