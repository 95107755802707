import React from "react";
import PlayButton from "../../images/play.png";

class GalleryMainMedia extends React.Component {
  render() {
    return (
      <div className="container-media-main">
        <img className="media-source" src={this.props.source} />
        <div className="media-play">
          <img className="media-play-button" src={PlayButton} />
        </div>
      </div>
    );
  }
}

export default GalleryMainMedia;
