import React from "react";
import ContainerPersonCard from "../PersonCard/ContainerPersonCard";
import { Link } from "react-router-dom";

import Image01 from "../../images/image-01.png";
import Image02 from "../../images/image-02.png";
import Image03 from "../../images/image-03.png";
import Image04 from "../../images/image-04.png";
import Image05 from "../../images/image-05.png";
import Image06 from "../../images/image-06.png";
import Image07 from "../../images/image-07.png";
import Image08 from "../../images/image-08.png";
import Image09 from "../../images/image-09.png";

class ContainerHome extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-3 col-xs-4 mb-1">
            <Link to="view/1">
              <ContainerPersonCard
                image={Image01}
                name={"داریوش رضایی نژاد"}
                operation={" هسته ای"}
              />
            </Link>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-3 col-xs-4 mb-1">
            <Link to="view/1">
              <ContainerPersonCard
                image={Image02}
                name={"حسن آیت"}
                operation={" جنگ هشت ساله عراق"}
              />
            </Link>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-3 col-xs-4 mb-1">
            <Link to="view/1">
              <ContainerPersonCard
                image={Image03}
                name={"محمود کاوه"}
                operation={" جنگ هشت ساله عراق"}
              />
            </Link>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-3 col-xs-4 mb-1">
            <Link to="view/1">
              <ContainerPersonCard
                image={Image04}
                name={"مرتضی آوینی"}
                operation={" جنگ هشت ساله عراق"}
              />
            </Link>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-3 col-xs-4 mb-1">
            <Link to="view/1">
              <ContainerPersonCard
                image={Image05}
                name={"مجید شهریاری"}
                operation={" هسته ای"}
              />
            </Link>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-3 col-xs-4 mb-1">
            <Link to="view/1">
              <ContainerPersonCard
                image={Image06}
                name={"محمد علی رجائی"}
                operation={" دفتر نخست وزیری"}
              />
            </Link>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-3 col-xs-4 mb-1">
            <Link to="view/1">
              <ContainerPersonCard
                image={Image07}
                name={"محمد جواد باهنر"}
                operation={" دفتر نخست وزیری"}
              />
            </Link>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-3 col-xs-4 mb-1">
            <Link to="view/1">
              <ContainerPersonCard
                image={Image08}
                name={"مصطفی چمران"}
                operation={" جنگ هشت ساله عراق"}
              />
            </Link>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-3 col-xs-4 mb-1">
            <Link to="view/1">
              <ContainerPersonCard
                image={Image09}
                name={"مصطفی احمدی روشن"}
                operation={" هسته ای"}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ContainerHome;
