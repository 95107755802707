import React from "react";

class Badge extends React.Component {
  render() {
    return (
      <label className={`badge ${this.props.type}`}>
        {this.props.children}
      </label>
    );
  }
}

export default Badge;
