import React from "react";
import SearchInput from "./SearchInput";
import "../../css/bootstrap.min.css";
import "../../css/index.css";
import Logo from "../../images/Logo.png";
import { Link } from "react-router-dom";

class HeaderContainer extends React.Component {
  render() {
    return (
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner">
                <SearchInput />
                <Link to="/">
                  <img src={Logo} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderContainer;
