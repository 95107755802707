import React from "react";
import GalleryMainMedia from "./GalleryMainMedia";

class Gallery extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="col-md-12">
          <h3>چند رسانه ای</h3>
          <GalleryMainMedia source={this.props.mainMediaSource} />
        </div>
        <div className="col-md-12 mt-1 mb-2">
          <div className="gallery">{this.props.children}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default Gallery;
