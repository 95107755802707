import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <div className="container mb-2 mt-2">
        <div className="row">
          <div className="col-md-12">
            <ul className="inline-list">
              <li className="inline-item">
                <a href="">تماس با ما</a>
              </li>
              <li className="inline-item">
                <a href="">داستان پروژه</a>
              </li>
              <li className="inline-item">
                <a href="">قوانین و مجوز ها</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
