import React from "react";
import PropTypes from "prop-types";
import PersonBrief from "./PersonBrief";
import PersonImage from "./PersonImage";

class ContainerPersonCard extends React.Component {
  render() {
    return (
      <div className="person-card-container">
        <PersonImage image={this.props.image} />
        <PersonBrief name={this.props.name} operation={this.props.operation} />
      </div>
    );
  }
}

ContainerPersonCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  operation: PropTypes.string
};

export default ContainerPersonCard;
