import React from "react";

class TableRow extends React.Component {
  render() {
    return (
      <div className="table-row" style={this.props.style && this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

export default TableRow;
