import React from "react";

class TableData extends React.Component {
  render() {
    const indicator = this.props.last ? "" : <span className="indicator" />;
    return (
      <div className="table-data" style={this.props.style && this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

export default TableData;
