import React from "react";

class Table extends React.Component {
  render() {
    return (
      <div className="tbl" style={this.props.style && this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

export default Table;
