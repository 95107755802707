import React from "react";

class Box extends React.Component {
  render() {
    return (
      <div style={this.props.style && this.props.style} className="box">
        {this.props.children}
      </div>
    );
  }
}

export default Box;
