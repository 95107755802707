var $ = function (selector) {
  return document.querySelector(selector);
};
var $$ = function (selector) {
  return document.querySelectorAll(selector);
};
var breadcrumb = $('.breadcrumb');
var breadcrumbSteps = $$('.breadcrumb__step');
[].forEach.call(breadcrumbSteps, function (item, index, array) {
  item.onclick = function () {
      for (var i = 0, l = array.length; i < l; i++) {
          if (index >= i) {
              array[i].classList.add('breadcrumb__step--active');
          }
          else {
              array[i].classList.remove('breadcrumb__step--active');
          }
      }
  };
});
