import React from "react";
import ContainerPersonCard from "../PersonCard/ContainerPersonCard";
import TableRow from "../Table/TableRow";
import Table from "../Table/Table";
import TableData from "../Table/TableData";
import Badge from "../Utils/Badge";
import Box from "../Utils/Box";

import Image01 from "../../images/image-01.png";
import Gallery from "../Gallery/Gallery";
import GalleryItem from "../Gallery/GalleryItem";

class ContainerViewPerson extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="row mb-2">
          <div className="col-md-12">
            <div id="page-wrap">
              <ul class="breadcrumb">
                <li>
                  <a href="#" className="active">
                    مدخل
                  </a>
                </li>
                <li>
                  <a href="#">جدول اطلاعات</a>
                </li>
                <li>
                  <a href="#">زندگینامه</a>
                </li>
                <li>
                  <a href="#">گفتاورد ها</a>
                </li>
                <li>
                  <a href="#">چند رسانه ای</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-9 col-xs-8 col-sm-9 col-lg-10">
            <Box style={{ overflowY: "auto", height: "140px" }}>
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
                نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
                کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان
                جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را
                برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در
                زبان فارسی ایجاد کرد.
              </p>
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
                نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
                کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان
                جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را
                برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در
                زبان فارسی ایجاد کرد.
              </p>
            </Box>
          </div>
          <div className="col-md-3 col-xs-4 col-sm-3 col-lg-2">
            <ContainerPersonCard
              image={Image01}
              name={"داریوش رضایی نژاد"}
              operation={" هسته ای"}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <Box style={{ padding: "0", border: "none" }}>
              <Table>
                <TableRow>
                  <TableData style={{ flexBasis: "200px" }}>
                    <label>تاریخ تولد</label>
                  </TableData>
                  <TableData>
                    <label>۲۲ مهر ماه ۱۳۹۷</label>
                  </TableData>
                </TableRow>
                <TableRow>
                  <TableData style={{ flexBasis: "200px" }}>
                    <label>عملیات های حضور یافته</label>
                  </TableData>
                  <TableData>
                    <Badge type={"red-light"}>بیت المقدس</Badge>
                    <Badge type={"red-light"}>کربلا پنج</Badge>
                  </TableData>
                </TableRow>
              </Table>
            </Box>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <Box>
              <h3>زندگینامه</h3>
              <h4>سربرگ اول</h4>
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
                نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
              </p>
              <h4>سربرگ دوم</h4>
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
                نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
              </p>
              <p>
                تابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان
                جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را
                برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در
                زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و
                دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد
                وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات
                پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
              </p>
              <h4>سربرگ سوم</h4>
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
                نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
              </p>
              <h4>سربرگ آخر</h4>
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
                نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
              </p>
            </Box>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <Box>
              <h3>گفتاورد ها</h3>
              <h4>
                <i>امر محمدی</i>
              </h4>
              <blockquote>
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                  استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و
                  مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                  تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                  کاربردی می باشد.
                </p>
              </blockquote>
              <h4>
                <i>رضا علیزاده</i>
              </h4>
              <blockquote>
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                  استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و
                  مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                  تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                  کاربردی می باشد.
                </p>
              </blockquote>
              <h4>
                <i>علی علی اکبری</i>
              </h4>
              <blockquote>
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                  استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و
                  مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                  تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                  کاربردی می باشد.
                </p>
              </blockquote>
            </Box>
          </div>
        </div>
        <div className="row mt-2 mb-2">
          <Gallery mainMediaSource="https://dummyimage.com/1920X1080/e0e0e0/e0e0e0">
            <GalleryItem
              source="https://dummyimage.com/230X120/e0e0e0/e0e0e0"
              playable
            />
            <GalleryItem source="https://dummyimage.com/230X120/e0e0e0/e0e0e0" />
            <GalleryItem source="https://dummyimage.com/230X120/e0e0e0/e0e0e0" />
            <GalleryItem source="https://dummyimage.com/230X120/e0e0e0/e0e0e0" />
            <GalleryItem source="https://dummyimage.com/230X120/e0e0e0/e0e0e0" />
            <GalleryItem source="https://dummyimage.com/230X120/e0e0e0/e0e0e0" />
            <GalleryItem source="https://dummyimage.com/230X120/e0e0e0/e0e0e0" />
          </Gallery>
        </div>
      </div>
    );
  }
}

export default ContainerViewPerson;
