import React from "react";
import PlayButton from "../../images/play.png";

class GalleryItem extends React.Component {
  render() {
    const playButton = this.props.playable ? (
      <div className="item-play">
        <img className="item-play-button" src={PlayButton} />
      </div>
    ) : (
      ""
    );
    return (
      <div className="gallery-item">
        <img src={this.props.source} />
        {playButton}
      </div>
    );
  }
}

export default GalleryItem;
