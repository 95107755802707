import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import HeaderContainer from "./FixedSections/HeaderContainer";
import ContainerHome from "./Pages/ContainerHome";
import ContainerViewPerson from "./Pages/ContainerViewPerson";
import Footer from "./FixedSections/Footer";

class App extends React.Component {
  render() {
    return (
      <Router>
        <div>
          <HeaderContainer />
          <Route path="/" exact component={ContainerHome} />
          <Route path="/view/:id" component={ContainerViewPerson} />
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
